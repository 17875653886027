<template>
	<div class="relative main-h-screen flex">
		<div class="w-full">
			<div class="bg-gray-100 bg-white">
				<div class="flex text-sm justify-center">
					<div class="w-fill bg-white shadow-md p-2">
						<nav class="flex mb-10" aria-label="Breadcrumb">
							<ol class="inline-flex items-center space-x-1 md:space-x-3">
								<li class="inline-flex items-center">
									<router-link
										:to="{ name: 'Home' }"
										class="inline-flex items-center text-sm text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white"
									>
										<svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
											<path
												d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
											></path>
										</svg>
										Home
									</router-link>
								</li>

								<li aria-current="page">
									<div class="flex items-center">
										<svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
											<path
												fill-rule="evenodd"
												d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
												clip-rule="evenodd"
											></path>
										</svg>
										<span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">{{ title }}</span>
									</div>
								</li>
							</ol>
							<hr />
						</nav>
						<div class="space-y-10">
							<div class="space-y-2">
								<h2 class="text-2xl text-gray-700 font-semibold">{{ title }}</h2>
							</div>
							<hr />
							<!-- START HERE -->

							<article class="article-body" id="article-body" rel="image-enlarge">
								<p dir="ltr">
									Before you begin, please note that the XCIPTV app will <strong><u dir="ltr">NOT</u></strong> have a working EPG TV guide or movie or tv show
									posters.
								</p>
								<p><br /></p>
								<p>
									We highly recommend using Tivimate app instead
									<router-link :to="{ name: 'tivimateInstallationInstructions' }" class="text-blue-400"
										><strong>Tivimate installation instructions</strong></router-link
									>
								</p>
								<p><br /></p>
								<p>In this guide we'll take you through setting up XCiptv player with our service.</p>
								<p><br /></p>
								<p>1. Search for xciptv and install the app via the App Store</p>
								<p>
									<img
										src="../../../assets/images/HNiJNwimRh_kzwJreeWP1zGqclpkjZf96w.png?1577115248"
										class="fr-fic fr-fil fr-dib"
										data-attachment="[object Object]"
										data-id="48020433198"
									/>
								</p>
								<p><br /></p>
								<p>2. Open the app, click on Settings</p>
								<p><br /></p>
								<p>
									<img
										src="../../../assets/images/4Jyzrubfmc2x0NJBU4mdx335E0kgu__Vuw.png?1577118600"
										class="fr-fic fr-fil fr-dib"
										data-attachment="[object Object]"
										data-id="48020441240"
									/>
								</p>
								<p><br /></p>
								<p>3. Choose M3U URL</p>
								<p>
									<img
										src="../../../assets/images/-KcbdFhc84qm4HZb4LRQRBC8cTXjlcO1dw.png?1577120449"
										class="fr-fic fr-fil fr-dib"
										data-attachment="[object Object]"
										data-id="48020445520"
									/>
								</p>
								<p><br /></p>
								<p>4. Enter the URL:</p>
								<p>
									<a :href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/livetv`"></a
									><a
										data-fr-linked="true"
										dir="ltr"
										:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/1`"
										style="box-sizing: border-box; color: rgb(8, 18, 29)"
									>
										{{ $store.state.brandInfo.tvLink }}</a
									>/api/list/<span style="color: rgb(0, 0, 0)"
										><strong
											><a href="/cdn-cgi/l/email-protection" class="__cf_email__" data-cfemail="234e5a464e424a4f63444e424a4f0d404c4e"
												>[email&#160;protected]</a
											></strong
										></span
									>/<span style="color: rgb(0, 0, 0)"><strong>myPass12</strong></span
									>/m3u8/livetv
								</p>
								<p>
									(assuming your email address is "<a href="/cdn-cgi/l/email-protection" class="__cf_email__" data-cfemail="204d59454d41494c60474d41494c0e434f4d"
										>[email&#160;protected]</a
									>" and your {{ $store.state.brandInfo.displayName }} pass is "myPass12" -case sensetive)
								</p>
								<p>
									<img
										src="../../../assets/images/AunKGiBixCMvW000hdRur5VTe9ZDfTDP4w.png?1577121577"
										class="fr-fic fr-fil fr-dib"
										data-attachment="[object Object]"
										data-id="48020447869"
									/>
								</p>
								<p><br /></p>
								<p>5. Once you signed in, you can click on All streams and start using the app.</p>
								<p>
									<img
										src="../../../assets/images/bqsPSwl3pe-Z_T4f9njrgUCPADJomhNh5g.png?1577122301"
										class="fr-fic fr-fil fr-dib"
										data-attachment="[object Object]"
										data-id="48020449430"
									/>
								</p>
								<p><br /></p>
								<p>6. To add VOD (TV Shows/Movies list) go back to the main window and clock on Account at the bottom.</p>
								<p>Then click +M3U</p>
								<p>
									<img
										src="../../../assets/images/8dYulGraW17jyMje-H67Y6RQ3SOZIFfMIw.png?1577122430"
										class="fr-fic fr-fil fr-dib"
										data-attachment="[object Object]"
										data-id="48020449567"
									/>
								</p>
								<p><br /></p>
								<p>7. Give it name TV Shows or movies and use the url:</p>
								<p>
									<a :href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/movies`"
										>{{ $store.state.brandInfo.tvLink }}/api/list/<strong
											><span class="__cf_email__" data-cfemail="92ffebf7fff3fbfed2f5fff3fbfebcf1fdff">[email&#160;protected]</span></strong
										>/<strong>myPass12</strong>/m3u8/movies</a
									><br />(assuming your email address is "<a
										href="/cdn-cgi/l/email-protection"
										class="__cf_email__"
										data-cfemail="91fce8f4fcf0f8fdd1f6fcf0f8fdbff2fefc"
										>[email&#160;protected]</a
									>" and your {{ $store.state.brandInfo.displayName }} pass is "myPass12" -case sensetive)
								</p>
								<p>Then click Add</p>
								<p><br /></p>
								<p>To add TV Shows use:</p>
								<p
									style="
										box-sizing: border-box;
										margin: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
										padding-top: 4px;
										color: rgb(8, 18, 29);
										font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
										font-style: normal;
										font-variant-ligatures: normal;
										font-variant-caps: normal;
										font-weight: 400;
										letter-spacing: normal;
										orphans: 2;
										text-align: start;
										text-indent: 0px;
										text-transform: none;
										white-space: normal;
										widows: 2;
										word-spacing: 0px;
										-webkit-text-stroke-width: 0px;
										text-decoration-style: initial;
										text-decoration-color: initial;
									"
								>
									<strong style="box-sizing: border-box; font-weight: bold">TV Shows (VERY big list. Don't use on old/small devices)</strong
									><br style="box-sizing: border-box" /><a
										data-fr-linked="true"
										:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/1`"
										style="box-sizing: border-box; color: rgb(8, 18, 29); text-decoration: none"
									></a
									><a
										data-fr-linked="true"
										dir="ltr"
										:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/1`"
										style="box-sizing: border-box; color: rgb(8, 18, 29)"
									>
										{{ $store.state.brandInfo.tvLink }}</a
									>/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/1 (Most new tv shows)
								</p>
								<p
									style="
										box-sizing: border-box;
										margin: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
										padding-top: 4px;
										color: rgb(8, 18, 29);
										font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
										font-style: normal;
										font-variant-ligatures: normal;
										font-variant-caps: normal;
										font-weight: 400;
										letter-spacing: normal;
										orphans: 2;
										text-align: start;
										text-indent: 0px;
										text-transform: none;
										white-space: normal;
										widows: 2;
										word-spacing: 0px;
										-webkit-text-stroke-width: 0px;
										text-decoration-style: initial;
										text-decoration-color: initial;
									"
								>
									<a
										data-fr-linked="true"
										:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/2`"
										style="box-sizing: border-box; color: rgb(8, 18, 29); text-decoration: none"
									></a
									><a
										data-fr-linked="true"
										dir="ltr"
										:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/1`"
										style="box-sizing: border-box; color: rgb(8, 18, 29)"
									>
										{{ $store.state.brandInfo.tvLink }}</a
									>/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/2
								</p>
								<p
									style="
										box-sizing: border-box;
										margin: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
										padding-top: 4px;
										color: rgb(8, 18, 29);
										font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
										font-style: normal;
										font-variant-ligatures: normal;
										font-variant-caps: normal;
										font-weight: 400;
										letter-spacing: normal;
										orphans: 2;
										text-align: start;
										text-indent: 0px;
										text-transform: none;
										white-space: normal;
										widows: 2;
										word-spacing: 0px;
										-webkit-text-stroke-width: 0px;
										text-decoration-style: initial;
										text-decoration-color: initial;
									"
								>
									<a
										data-fr-linked="true"
										:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/3`"
										style="box-sizing: border-box; color: rgb(8, 18, 29); text-decoration: none"
									></a
									><a
										data-fr-linked="true"
										dir="ltr"
										:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/1`"
										style="box-sizing: border-box; color: rgb(8, 18, 29)"
									>
										{{ $store.state.brandInfo.tvLink }}</a
									>/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/3
								</p>
								<p
									style="
										box-sizing: border-box;
										margin: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
										padding-top: 4px;
										color: rgb(8, 18, 29);
										font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
										font-style: normal;
										font-variant-ligatures: normal;
										font-variant-caps: normal;
										font-weight: 400;
										letter-spacing: normal;
										orphans: 2;
										text-align: start;
										text-indent: 0px;
										text-transform: none;
										white-space: normal;
										widows: 2;
										word-spacing: 0px;
										-webkit-text-stroke-width: 0px;
										text-decoration-style: initial;
										text-decoration-color: initial;
									"
								>
									<a
										data-fr-linked="true"
										:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/4`"
										style="box-sizing: border-box; color: rgb(8, 18, 29); text-decoration: none"
									></a
									><a
										data-fr-linked="true"
										dir="ltr"
										:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/1`"
										style="box-sizing: border-box; color: rgb(8, 18, 29)"
									>
										{{ $store.state.brandInfo.tvLink }}</a
									>/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/4
								</p>
								<p
									style="
										box-sizing: border-box;
										margin: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
										padding-top: 4px;
										color: rgb(8, 18, 29);
										font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
										font-style: normal;
										font-variant-ligatures: normal;
										font-variant-caps: normal;
										font-weight: 400;
										letter-spacing: normal;
										orphans: 2;
										text-align: start;
										text-indent: 0px;
										text-transform: none;
										white-space: normal;
										widows: 2;
										word-spacing: 0px;
										-webkit-text-stroke-width: 0px;
										text-decoration-style: initial;
										text-decoration-color: initial;
									"
								>
									<a
										data-fr-linked="true"
										:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/5`"
										style="box-sizing: border-box; color: rgb(8, 18, 29); text-decoration: none; outline: 0px"
									></a
									><a
										data-fr-linked="true"
										dir="ltr"
										:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/1`"
										style="box-sizing: border-box; color: rgb(8, 18, 29)"
									>
										{{ $store.state.brandInfo.tvLink }}</a
									>/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/5
								</p>
								<p
									style="
										box-sizing: border-box;
										margin: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
										padding-top: 4px;
										color: rgb(8, 18, 29);
										font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
										font-style: normal;
										font-variant-ligatures: normal;
										font-variant-caps: normal;
										font-weight: 400;
										letter-spacing: normal;
										orphans: 2;
										text-align: start;
										text-indent: 0px;
										text-transform: none;
										white-space: normal;
										widows: 2;
										word-spacing: 0px;
										-webkit-text-stroke-width: 0px;
										text-decoration-style: initial;
										text-decoration-color: initial;
									"
								>
									<a
										data-fr-linked="true"
										:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/6`"
										style="box-sizing: border-box; color: rgb(8, 18, 29); text-decoration: none"
									></a
									><a
										data-fr-linked="true"
										dir="ltr"
										:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/1`"
										style="box-sizing: border-box; color: rgb(8, 18, 29)"
									>
										{{ $store.state.brandInfo.tvLink }}</a
									>/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/6
								</p>
								<p
									style="
										box-sizing: border-box;
										margin: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
										padding-top: 4px;
										color: rgb(8, 18, 29);
										font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
										font-style: normal;
										font-variant-ligatures: normal;
										font-variant-caps: normal;
										font-weight: 400;
										letter-spacing: normal;
										orphans: 2;
										text-align: start;
										text-indent: 0px;
										text-transform: none;
										white-space: normal;
										widows: 2;
										word-spacing: 0px;
										-webkit-text-stroke-width: 0px;
										text-decoration-style: initial;
										text-decoration-color: initial;
									"
								>
									<a
										data-fr-linked="true"
										:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/7`"
										style="box-sizing: border-box; color: rgb(8, 18, 29); text-decoration: none"
									></a
									><a
										data-fr-linked="true"
										dir="ltr"
										:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/1`"
										style="box-sizing: border-box; color: rgb(8, 18, 29)"
									>
										{{ $store.state.brandInfo.tvLink }}</a
									>/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/7
								</p>
								<p
									style="
										box-sizing: border-box;
										margin: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
										padding-top: 4px;
										color: rgb(8, 18, 29);
										font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
										font-style: normal;
										font-variant-ligatures: normal;
										font-variant-caps: normal;
										font-weight: 400;
										letter-spacing: normal;
										orphans: 2;
										text-align: start;
										text-indent: 0px;
										text-transform: none;
										white-space: normal;
										widows: 2;
										word-spacing: 0px;
										-webkit-text-stroke-width: 0px;
										text-decoration-style: initial;
										text-decoration-color: initial;
									"
								>
									<a
										data-fr-linked="true"
										:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/8`"
										style="box-sizing: border-box; color: rgb(8, 18, 29); text-decoration: none"
									></a
									><a
										data-fr-linked="true"
										dir="ltr"
										:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/1`"
										style="box-sizing: border-box; color: rgb(8, 18, 29)"
									>
										{{ $store.state.brandInfo.tvLink }}</a
									>/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/8
								</p>
								<p
									style="
										box-sizing: border-box;
										margin: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
										padding-top: 4px;
										color: rgb(8, 18, 29);
										font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
										font-style: normal;
										font-variant-ligatures: normal;
										font-variant-caps: normal;
										font-weight: 400;
										letter-spacing: normal;
										orphans: 2;
										text-align: start;
										text-indent: 0px;
										text-transform: none;
										white-space: normal;
										widows: 2;
										word-spacing: 0px;
										-webkit-text-stroke-width: 0px;
										text-decoration-style: initial;
										text-decoration-color: initial;
									"
								>
									<a
										data-fr-linked="true"
										:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/9`"
										style="box-sizing: border-box; color: rgb(8, 18, 29); text-decoration: none"
									></a
									><a
										data-fr-linked="true"
										dir="ltr"
										:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/1`"
										style="box-sizing: border-box; color: rgb(8, 18, 29)"
									>
										{{ $store.state.brandInfo.tvLink }}</a
									>/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/9&nbsp;
								</p>
								<p
									style="
										box-sizing: border-box;
										margin: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
										padding-top: 4px;
										color: rgb(8, 18, 29);
										font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
										font-style: normal;
										font-variant-ligatures: normal;
										font-variant-caps: normal;
										font-weight: 400;
										letter-spacing: normal;
										orphans: 2;
										text-align: start;
										text-indent: 0px;
										text-transform: none;
										white-space: normal;
										widows: 2;
										word-spacing: 0px;
										-webkit-text-stroke-width: 0px;
										text-decoration-style: initial;
										text-decoration-color: initial;
									"
								>
									(Oldest tv shows)
								</p>
								<p
									style="
										box-sizing: border-box;
										margin: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
										padding-top: 4px;
										color: rgb(8, 18, 29);
										font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
										font-style: normal;
										font-variant-ligatures: normal;
										font-variant-caps: normal;
										font-weight: 400;
										letter-spacing: normal;
										orphans: 2;
										text-align: start;
										text-indent: 0px;
										text-transform: none;
										white-space: normal;
										widows: 2;
										word-spacing: 0px;
										-webkit-text-stroke-width: 0px;
										text-decoration-style: initial;
										text-decoration-color: initial;
									"
								>
									<br />
								</p>
								<p>
									<img
										src="../../../assets/images/LVYAxdsTSHETq_QK8Z0gWu0YFEr8_NsgfQ.png?1577122562"
										class="fr-fic fr-fil fr-dib"
										data-attachment="[object Object]"
										data-id="48020449930"
									/>
								</p>
								<p><br /></p>
								<p>8. Click on the M3U we created and then click Login</p>
								<p>
									<img
										src="../../../assets/images/0hT-hVqdZ26ttZBPwfqo09A5-vIHLXn3Yg.png?1577123694"
										class="fr-fic fr-fil fr-dib"
										data-attachment="[object Object]"
										data-id="48020452122"
									/>
								</p>
								<p><br /></p>
								<p>10. To use Multi Screen go to the main menu (make sure you're in the right Playlist - LiveTV most likely)</p>
								<p>Click on Multi at the bottom</p>
								<p>
									<img
										src="../../../assets/images/wSIwX-Yb_58qXnwiDsKUuG9JUuJT4POtcw.png?1577124045"
										class="fr-fic fr-fil fr-dib"
										data-attachment="[object Object]"
										data-id="48020452751"
									/>
								</p>
								<p><br /></p>
								<p>11. A 4 screen window will open up, click on one of the screens you want to add a stream to</p>
								<p>
									<img
										src="../../../assets/images/dkxSvvYn1TM6gFwmh8loDzsIY1zvh6X5qQ.png?1577124440"
										class="fr-fic fr-fil fr-dib"
										data-attachment="[object Object]"
										data-id="48020453596"
									/>
								</p>
								<p><br /></p>
								<p>12. Click on the Plus and choose the stream you want to add, do the same for all the screens and there you go.</p>
								<p>
									<img
										src="../../../assets/images/pgsyWESRRzNYJ2fUrUg05yQbGP9KFdnSQw.png?1577124500"
										class="fr-fic fr-fil fr-dib"
										data-attachment="[object Object]"
										data-id="48020453714"
									/>
								</p>
								<p><br /></p>
								<p>13. Continue to add to the windows you want</p>
								<p>
									<img
										src="../../../assets/images/8KSnAKz6Qd88wd4dE-qd3UkUFiQjYyONMw.png?1577124535"
										class="fr-fic fr-fil fr-dib"
										data-attachment="[object Object]"
										data-id="48020453777"
									/>
								</p>
								<p><br /></p>
								<p>That's all.</p>
							</article>

							<!-- FINISH HERE -->
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'xCiptvInstallationInstructionMultiScreen',
	data() {
		return {
			title: this.$route.meta.title
		};
	}
};
</script>

<style scoped></style>
